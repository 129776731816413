<template>
  <n-form-item class="v-textarea" :title="title">
    <wysiwyg :html="value" @html="input" />
  </n-form-item>
</template>

<script>
export default {
  name: 'NTextarea',
  props: {
    title: { type: String, default: '', },
    value: { type: String, default: '', },
    input: { type: Function, default: (value) => {}, },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.v-textarea {
}
</style>
