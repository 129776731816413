export default {
  app: {
    namespaced: true,
    state: {
      locale: null,
      loading: false,

      _save: [ 'locale', ],
    },

    getters: {
      locale(state) {
        return state.locale || { title: 'ru', value: 'ru', }
      },
    },

    mutations: {
      loading(state, value) {
        state.loading = value
      },
      locale(state, value) {
        state.locale = value
        $app.api.config({ headers: { 'Locale': value.value, }, })
      },
    },
  },
}
