import CPageTitle from 'components/pageTitle/Index'
import TextArea from 'components/textarea/Index'
import CDatepicker from './CDatepicker'
import Qr from './Qr'


export default {
  CPageTitle,
  TextArea,
  CDatepicker,
  Qr,
}
