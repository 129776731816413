export default [
  { path: '', component: () => import('layouts/App'), children: [

    { path: '', component: () => import('layouts/main/Index'), children: [
      // { path: '', name: 'index', icon: 'chart-pie', component: () => import('pages/stats/Index'), },
      { path: 'quizzes/stats/:id', title: 'Статистика',
        name: 'quizzes.stats', icon: 'chart-line', component: () => import('pages/stats/Quiz'), parent: 'quizzes.list', },

      { path: '', name: 'quizzes.list', title: 'Опросы', icon: 'comments', component: () => import('pages/quizzes/Index'), },

      { path: 'quizzes/create', title: 'Создание опроса',
        name: 'quizzes.create', icon: 'plus', component: () => import('pages/quizzes/Create'), parent: 'quizzes.list', },

      { path: 'quizzes/edit/:id', title: 'Редактировать опрос',
        name: 'quizzes.edit', icon: 'portrait', component: () => import('pages/quizzes/Edit'), parent: 'quizzes.list', },

      { path: 'users', title: 'Пользователи', name: 'users.list', icon: 'users', component: () => import('pages/users/Index'), },

      { path: 'users/create', title: 'Добавление пользователя',
        name: 'users.create', icon: 'plus', component: () => import('pages/users/Create'), parent: 'users.list', },

      { path: 'tariffs', title: 'Тарифы', name: 'tariffs.list', icon: 'users', component: () => import('pages/tariffs/Index'), },

      { path: 'tariffs/edit/:id', title: 'Редатирование тарифа',
        name: 'tariffs.edit', icon: 'portrait', component: () => import('pages/tariffs/Edit'), parent: 'tariffs.list', },

    ], },

    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: 'login', title: 'Авторизация', name: 'login', component: () => import('pages/auth/login/Index'), },
    ], },
  ], },
]
