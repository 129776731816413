/**
 * 
 */
export default class RestApi {
  /**
   * Auth
   */
  auth = {
    info: () => $app.api.get([ 'auth/info', ]),
    login: (data) => $app.api.post('auth/login').data(data),
    register: (data, token = '') => $app.api.post([ 'auth/register*', token, ]).data(data),
  }

  /**
   * Base
   */
  files = {
    get: (id) => $app.api.get([ 'files*', id, ]),
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
    delete: (id) => $app.api.delete([ 'files*', id, ]),
  }
  locale = {
    get: (id) => $app.api.get([ 'locales*', id, ]),
    set: (id) => $app.api.post([ 'locales*', id, ]),
    elements: {
      get: () => $app.api.get([ 'elements', ]),
    },
  }
  translations = {
    getByTag: (tag) => $app.api.get([ 'base/translations*', tag, ]),
    edit: (id, data) => $app.api.patch([ 'base/translations*', id, ]).data(data),
  }

  /**
   * App
   */
  app = {
    quizzes: {
      get: (id) => $app.api.get([ 'quizzes*', id, ]),
      create: (data) => $app.api.post([ 'quizzes', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'quizzes*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'quizzes*', id, ]),
    },
    questions: {
      get: (id) => $app.api.get([ 'questions*', id, ]),
      create: (data) => $app.api.post([ 'questions', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'questions*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'questions*', id, ]),
    },
    variants: {
      get: (id) => $app.api.get([ 'variants*', id, ]),
      create: (data) => $app.api.post([ 'variants', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'variants*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'variants*', id, ]),
    },
    answers: {
      get: (quizId) => $app.api.get([ 'quizzes*/answers', quizId, ]),
    },
    tariffs: {
      get: (id) => $app.api.get([ 'tariffs*', id, ]),
      edit: (id, data) => $app.api.patch([ 'tariffs*', id, ]).data(data),
    },
    users: {
      get: (id) => $app.api.get([ 'users*', id, ]),
      create: (data) => $app.api.post([ 'users', ]).data(data),
      tariffs: {
        set: (userId, data) => $app.api.post([ 'users*/tariff*', userId, ]).data(data),
      },
    },
  }
}
